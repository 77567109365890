body {
  font-family: "Gill Sans", sans-serif;
  background-color: rgb(255, 160, 220);
}

.main-container {
  margin-bottom: 250px;
  padding: 100;
  display: grid;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.heading-container {
  text-align: center;
}

.text-container {
  margin: 30px;
  text-align: center;
}

.text-container2 {
  margin: 30px;
  text-align: center;
  padding-bottom: 60px;
}

#interactive-heading {
  cursor: pointer;
  color: #eee7e3;
  transition: color 0.9s ease-out;
}

#interactive-heading:hover {
  color: rgb(175, 66, 139);
}

h1 {
  color: hotpink;
  font-family: "Gill Sans", sans-serif;
  font-size: 85px;
  margin: 10px;
}

p {
  font-family: "Gill Sans", sans-serif;
  line-height: 1.5em;
  margin: 0;
  padding: 0;
}

a {
  color: rgb(175, 66, 139);
}

footer {
  background-color: #f2a2e3;
  color: #fff;
  padding: 20px 20px 20px 20px;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: auto;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 100px;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: rgb(175, 66, 139) wavy underline;;
}


