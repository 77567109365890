body {
  background-color: #ffa0dc;
  font-family: Gill Sans, sans-serif;
}

.main-container {
  height: 60vh;
  justify-content: center;
  align-items: center;
  margin-bottom: 250px;
  padding: 100px;
  display: grid;
}

.heading-container {
  text-align: center;
}

.text-container {
  text-align: center;
  margin: 30px;
}

.text-container2 {
  text-align: center;
  margin: 30px;
  padding-bottom: 60px;
}

#interactive-heading {
  cursor: pointer;
  color: #eee7e3;
  transition: color .9s ease-out;
}

#interactive-heading:hover {
  color: #af428b;
}

h1 {
  color: #ff69b4;
  margin: 10px;
  font-family: Gill Sans, sans-serif;
  font-size: 85px;
}

p {
  margin: 0;
  padding: 0;
  font-family: Gill Sans, sans-serif;
  line-height: 1.5em;
}

a {
  color: #af428b;
}

footer {
  color: #fff;
  text-align: center;
  background-color: #f2a2e3;
  padding: 20px;
}

.footer-content {
  max-width: 1200px;
  margin: auto;
}

nav ul {
  padding: 0;
  list-style: none;
}

nav ul li {
  margin-right: 100px;
  display: inline;
}

nav ul li:last-child {
  margin-right: 0;
}

nav ul li a {
  color: #fff;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline wavy #af428b;
}

/*# sourceMappingURL=index.a7f74d7f.css.map */
